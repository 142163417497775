<template>
  <div class="register-container">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="账号" prop="username">
        <el-input v-model="form.username" :disabled="judgeAddOrEdit" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="form.password" :disabled="disabled" :show-password="showpassword" style="width: 80%"></el-input>
      </el-form-item>
      <!-- 其他表单项... -->
      <el-form-item label="银行卡号" prop="yhnumber">
        <el-input v-model="form.yhnumber" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="令牌" prop="value">
        <el-input v-model="form.value" style="width: 80%"></el-input>
      </el-form-item>
    </el-form>
    <div class="button-container">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      showpassword: true,
      judgeAddOrEdit: true,
      form: {
        username: '',
        password: '',
        // 其他表单项...
        yhnumber: '',
        value: '',
      },
      rules: {
        // 表单验证规则...
      },
    };
  },
  computed: {
    disabled() {
      // 计算是否禁用表单提交按钮的逻辑
    },
  },
  methods: {
    // 表单验证方法和其他方法...
  },
};
</script>

<style scoped>
.register-container {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
}
.button-container {
  text-align: right;
  margin-top: 20px;
}
</style>

<!--<script src="@/assets/js/Login.js"></script>-->
<!--<style scoped>@import '../assets/css/Login.css';</style>-->
